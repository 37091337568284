import './App.css';
import Cookies from 'js-cookie';

function App() {
  const getAuthCookie = () => {
    return Cookies.get("user_token");
  };

  const userAuthToken = getAuthCookie();
  return (
    <div className="App">
      <header className="App-header">
      <div>
        <p>Kitabisa.com User Auth Token: {userAuthToken}</p>
      </div>
      </header>
    </div>
  );
}

export default App;
